import React from "react"
import {colors} from "../../constants/css"

const Bullet = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 48 48"
      style={{height: "8px", paddingLeft: "8px"}}
    >
      <g id="Layer_3">
        <circle className="st0" cx="24" cy="24" r="24" fill={colors.white} />
      </g>
    </svg>
  )
}

export default Bullet
