// 3rd party
import React, {useContext, useEffect, useRef} from "react"
import {graphql as gql, useStaticQuery} from "gatsby"
import styled from "styled-components"

// constants
import {sizes, transition, device} from "../../constants/css"

// context
import {GlobalStateContext, GlobalDispatchContext} from "../../context/GlobalContextProvider"

// components
import NavMainItem from "./navMainItem"
import Scrollable from "../general/scrollable"

const NavMain = ({activeRef, scrollRef, handleScroll}) => {
  // state
  const appState = useContext(GlobalStateContext)
  const appDispatch = useContext(GlobalDispatchContext)
  const myRef = useRef()

  // data
  const data = useStaticQuery(query)
  let navData = appState.navData[appState.navType]
  let items = []
  items = navData?.filter(el => {
    return el.node.parentId === null || !("parentId" in el.node)
  })
  items = items?.sort((a, b) => a.node.termOrder + 0 - (b.node.termOrder + 0))

  // effect
  useEffect(() => {
    const {mediums, projects, works} = data
    let allNavData = {medium: mediums.edges, project: projects.edges, works: works.edges}
    appDispatch({type: "setNavData", value: allNavData})
  }, [appDispatch, data])

  return (
    <Scrollable myRef={scrollRef} handleScroll={handleScroll}>
      <NavMenu
        collapsed={appState.navIsCollapsed ? 1 : 0}
        onClick={() => {
          appDispatch({type: "decollapseNav"})
          setTimeout(() => {
            appDispatch({type: "unfreezeNav"})
          }, transition.nav)
        }}
      >
        <Ul ref={myRef}>
          {items?.map(({node}) => {
            return !node.parent && <NavMainItem item={node} key={node.id} myRef={activeRef} />
          })}
        </Ul>
      </NavMenu>
    </Scrollable>
  )
}

const Ul = styled.ul`
  width: ${sizes.nav0Width.small}px;
  @media ${device.tabletM} {
    width: ${sizes.nav0Width.large}px;
  }
  overflow: visible;
`

const NavMenu = styled.div`
  height: 100%;
  cursor: ${({collapsed}) => (collapsed ? "pointer" : "default")};
  text-align: right;

  padding-bottom: 256px;
  font-size: 0.625rem;
  @media ${device.tabletM} {
    padding-top: 44px;
    font-size: 0.75rem;
  }
`
const query = gql`
  query navQuery {
    mediums: allWpMediumType {
      edges {
        node {
          ...mediumFields
          ...featuredImageRoundMedium
          ...previewImageMedium
          parent: wpParent {
            node {
              id: databaseId
            }
          }
        }
      }
    }
    projects: allWpProject {
      edges {
        node {
          ...projectFields
          ...featuredImageRoundProject
          ...previewImageProject
          mainSub {
            mainSub {
              ... on WpWork {
                id: databaseId
              }
            }
          }
          parent: wpParent {
            node {
              id: databaseId
            }
          }
        }
      }
    }
    works: allWpWork {
      edges {
        node {
          ...workFields
          ...featuredImageRoundWork
          date
          description: details {
            content: description
          }
          medium: mediumTypes {
            nodes {
              id: databaseId
            }
          }
          project: projects {
            nodes {
              id: databaseId
            }
          }
        }
      }
    }
  }
`

export default NavMain
